// Titulo
// ==========================================================================
// (function () {
//   try {
//     if (!isInPage(".el"))
//       return false;
//   } catch (error) {
//     console.log(error.name + ": " + error.message);
//   }
// }());


// https://developer.mozilla.org/en-US/docs/Web/API/Node/contains
function isInPage(element) {
  var node = document.querySelector(element);
  return (node === document.body) ? false : document.body.contains(node);
}

function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

jQuery(document).ready(function (e) {

  // Awesome Select
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("select[data-placeholder]"))
        return false;

      $('select[data-placeholder]').awselect({
        immersive: false,
        background: "transparent",
        vertical_padding: 0,
        horizontal_padding: 0,
      })
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());

  // Fixar o menu no topo da página
  // ==========================================================================
  (function () {
    try {
      if (!isInPage(".page.-fixed-header"))
        return false;

      var page = document.getElementById("page");
      var header = document.getElementById("mainHeader");
      var masthead = document.getElementById("masthead");
      var content = document.getElementById("mainContent");
      var auxHeightheader = header.clientHeight;

      header.classList.add("fixed-top");

      if (!page.classList.contains("-transparent-header"))
        ajustarPosicaoHeader()

      if (!page.classList.contains("-transparent-header"))
        window.addEventListener("resize", ajustarPosicaoHeader);

      window.addEventListener("scroll", fixarHeader);

      function ajustarPosicaoHeader() {
        content.style.paddingTop = auxHeightheader + "px";
      }

      function fixarHeader() {
        var pontoDeCorte = masthead.offsetHeight;

        if (!page.classList.contains("-fluid-header"))
          pontoDeCorte = auxHeightheader + masthead.offsetHeight;

        if (window.pageYOffset >= pontoDeCorte)
          header.classList.add("is-fixed");
        else
          header.classList.remove("is-fixed");
      }

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Gerar o menu para a versão mobile
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#mobileMenu"))
        return false;

      $("#mobileMenu").mmenu({
        extensions: ["pagedim-black", "position-left", "listview-50", "border-offset"],
        navbar: {
          title: "NTC Eventos"
        },
        navbars: [{
          position: "bottom",
          content: [
            "<a class='fa fa-envelope' href='#/'></a>",
            "<a class='fa fa-twitter'  href='#/'></a>",
            "<a class='fa fa-facebook' href='#/'></a>"
          ]
        }]
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Animaçao de texto: TYPED/digitaçao
  // ==========================================================================
  (function () {
    try {
      if (!isInPage(".typedjs"))
        return false;

      // Sobre nos
      var typedSobrenos = new Typed('.typedjs.-sobrenos', {
        stringsElement: '.typedjs-string.-sobrenos',
        typeSpeed: 100,
        backDelay: 1000,
        backSpeed: 20,
        loop: true
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Calendario - popover do evento
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#calendario .item"))
        return false;

      $("#calendario .item").popover({
        html: true,
        placement: 'top',
        container: '#calendario',
        content: function () {
          return $(this).next('.popover-content').html();
        }
      });

      $("#calendario .item").hover(function () {
        $(this).popover('show')
      });

      $("#calendario .item").mouseleave(function () {
        $(this).popover('hide')
      });

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Animaçao de texto: Shuffle
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("[data-chaffle]"))
        return false;

      var elements = document.querySelectorAll('[data-chaffle]');

      Array.prototype.forEach.call(elements, function (el) {
        var chaffle = new Chaffle(el, {
          delay: 50
        });

        el.addEventListener('mouseover', function () {
          chaffle.init();
        });
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Animação Progressbar - Sobre nos
  // ==========================================================================
  (function () {
    try {
      if (!isInPage(".pb-item"))
        return false;

      var elements = document.querySelectorAll('.listagem-progressbar .pb-item');

      function onElementInViewport() {
        Array.prototype.forEach.call(elements, function (el) {
          var value = parseInt(el.querySelectorAll('.pb-value')[0].innerHTML);
          var bar = el.querySelectorAll('.progress-bar')[0];
          if (isElementInViewport(el)) {
            bar.style.width = value + "%";
          }
        });
      }

      addEventListener('DOMContentLoaded', onElementInViewport, false);
      addEventListener('load', onElementInViewport, false);
      addEventListener('scroll', onElementInViewport, false);
      addEventListener('resize', onElementInViewport, false);

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Multimídia - Galeria de fotos
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#multimidiaGallery"))
        return false;

      $("#multimidiaGallery .mgallery").lightGallery({
        thumbnail: true,
        animateThumb: false,
        showThumbByDefault: true,
        download: false,
        share: false,
        actualSize: false
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Parallax
  // ==========================================================================
  // $(".parallax").parallax();


  // Animação ao fazer scroll na página
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("[data-aos]"))
        return false;

      AOS.init({
        duration: 500
      });

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Animação ao fazer scroll na página
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#datepicker"))
        return false;

      $('#datepicker').datepicker({
        language: 'pt-BR',
        minDate: new Date()
      });

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Formulario de Newsletter
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#formNewsletter"))
        return false;

      $('#formNewsletter').validate({
        debug: false,
        errorClass: "error",
        errorElement: "div",
        onkeyup: false,
        rules: {
          emailNewsletter: {
            required: true,
            email: true
          }
        }
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Noticia - Formulario de comentario
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#formComentario"))
        return false;

      $('#formComentario').validate({
        debug: false,
        errorClass: "error",
        errorElement: "div",
        onkeyup: false,
        rules: {
          inNome: {
            required: true,
            minlength: 4
          },
          inEmail: {
            required: true,
            email: true
          },
          inMensagem: {
            required: true,
            minlength: 10
          }
        }
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());

  // Sobre nos - Formulario de sugestao
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#formSugestao"))
        return false;

      $('#formSugestao').validate({
        debug: false,
        errorClass: "error",
        errorElement: "div",
        onkeyup: false,
        rules: {
          inNome: {
            required: true,
            minlength: 4
          },
          inEmail: {
            required: true,
            email: true
          },
          inAssunto: {
            required: true
          },
          inMensagem: {
            required: true,
            minlength: 10
          }
        }
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());

  // In Company - Formulario de Contrate
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#formContrate"))
        return false;

      $('#formContrate').validate({
        debug: false,
        errorClass: "error",
        errorElement: "div",
        onkeyup: false,
        rules: {
          inNome: {
            required: true,
            minlength: 4
          },
          inEmail: {
            required: true,
            email: true
          },
          inEmpresa: {
            required: true
          },
          inSolicitacao: {
            required: true,
            minlength: 10
          }
        }
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Anumacao: Counter/Contador
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("[data-lemcounter]"))
        return false;

      var elements = document.querySelectorAll('[data-lemcounter]');

      function onElementInViewport() {
        Array.prototype.forEach.call(elements, function (el) {
          var valueTo = el.getAttribute('data-lemcounter');
          if ($(el).hasClass("-lem")) return false;
          if (isElementInViewport(el)) {
            $(el).lemCounter({
              value_to: valueTo
            }).addClass("-lem");
          }
        });
      }

      addEventListener('DOMContentLoaded', onElementInViewport, false);
      addEventListener('load', onElementInViewport, false);
      addEventListener('scroll', onElementInViewport, false);
      addEventListener('resize', onElementInViewport, false);

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Revolution Slider
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#homeSlider"))
        return false;

      var homeSlider = $('#homeSlider').show().revolution({
        sliderType: "standard",
        sliderLayout: "fullwidth",
        delay: 9000,
        // extensions: "",
        navigation: {
          arrows: {
            style: "hermes",
            enable: true,
            hide_onmobile: true,
            hide_under: 600,
            hide_onleave: true,
            hide_delay: 200,
            hide_delay_mobile: 1200,
            tmp: '<div class="tp-arr-allwrapper"><div class="tp-arr-imgholder"></div></div>',
            left: {
              h_align: "left",
              v_align: "center",
              h_offset: 0,
              v_offset: 0
            },
            right: {
              h_align: "right",
              v_align: "center",
              h_offset: 0,
              v_offset: 0
            }
          }
        },
        gridwidth: [1200, 992, 460, 360, 320],
        gridheight: [519, 519, 460, 320]
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Evento - Galeria de fotos
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#galeria"))
        return false;

      var eventoGaleria = new Masonry('#galeria .galeria', {
        itemSelector: '.item-galeria',
        columnWidth: '.item-galeria',
        percentPosition: true
      });

      $("#galeria .galeria").lightGallery({
        thumbnail: true,
        animateThumb: false,
        showThumbByDefault: true,
        download: false,
        share: false,
        actualSize: false
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Eventos - Botao mostrar mapa
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#btnMostraMapa"))
        return false;

      var btn = document.getElementById("btnMostraMapa");
      var map = document.querySelector("#masthead .masthead-container");
      var header = document.getElementById("mainHeader");

      function mostraMapa() {
        map.classList.toggle("-fullscreen");

        if (map.classList.contains("-fullscreen"))
          btn.innerHTML = "Esconder Mapa"
        else
          btn.innerHTML = "Mostrar Mapa"

      }

      btn.addEventListener("click", mostraMapa);
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());

  // Share - Plugin de compartilhamento
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#jsSocials"))
        return false;

      $("#jsSocials").jsSocials({
        showLabel: false,
        showCount: false,
        shares: ["facebook", "linkedin", "twitter", "googleplus"]
      });

      $("#jsSocials").stick_in_parent();

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // PARTICULAS - Animação do banner na página evento
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#mastheadEvento"))
        return false;

      particlesJS("mastheadEvento", {
        "particles": {
          "number": {
            "value": 80,
            "density": {
              "enable": true,
              "value_area": 800
            }
          },
          "color": {
            "value": "#ffffff"
          },
          "shape": {
            "type": "circle",
            "stroke": {
              "width": 0,
              "color": "#000000"
            },
            "polygon": {
              "nb_sides": 5
            },
            "image": {
              "src": "img/github.svg",
              "width": 100,
              "height": 100
            }
          },
          "opacity": {
            "value": 0.5,
            "random": false,
            "anim": {
              "enable": false,
              "speed": 1,
              "opacity_min": 0.1,
              "sync": false
            }
          },
          "size": {
            "value": 3,
            "random": true,
            "anim": {
              "enable": false,
              "speed": 10,
              "size_min": 0.1,
              "sync": false
            }
          },
          "line_linked": {
            "enable": true,
            "distance": 150,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 1
          },
          "move": {
            "enable": true,
            "speed": 1,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
              "enable": false,
              "rotateX": 600,
              "rotateY": 1200
            }
          }
        },
        "interactivity": {
          "detect_on": "canvas",
          "events": {
            "onhover": {
              "enable": true,
              "mode": "repulse"
            },
            "onclick": {
              "enable": true,
              "mode": "push"
            },
            "resize": true
          },
          "modes": {
            "grab": {
              "distance": 400,
              "line_linked": {
                "opacity": 1
              }
            },
            "bubble": {
              "distance": 400,
              "size": 40,
              "duration": 2,
              "opacity": 8,
              "speed": 3
            },
            "repulse": {
              "distance": 200,
              "duration": 0.4
            },
            "push": {
              "particles_nb": 4
            },
            "remove": {
              "particles_nb": 2
            }
          }
        },
        "retina_detect": true
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // MegaMenu
  // ==========================================================================
  (function () {
    try {
      if (!isInPage(".main-navigation .dropdown"))
        return false;

      // when you hover a toggle show its dropdown menu
      $(".main-navigation .dropdown").hover(function () {
        // $(this).parent().toggleClass("show");
        $(this).find(".dropdown-menu").toggleClass("show");
      });

      // hide the menu when the mouse leaves the dropdown
      $(".main-navigation .dropdown").mouseleave(function () {
        $(this).removeClass("show");
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Página Galeria de fotos
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#galeriadefotos"))
        return false;

      $("#galeriadefotos .mgallery").lightGallery({
        thumbnail: true,
        animateThumb: false,
        showThumbByDefault: true,
        download: false,
        share: false,
        actualSize: false
      });
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Página Galeria de fotos
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#artigosPareceres"))
        return false;

      var options = {
        valueNames: ['title', 'desc', 'datetime', 'author']
      };

      var userList = new List('artigosPareceres', options);

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Twitter - página palestrantes
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#tweets"))
        return false;

      // $('#tweets').tweetie({
      //   "url": "https://files.sonnyt.com/tweetie/v3/",
      //   "type": "timeline",
      //   "template": "<li>{{tweet.created_at}} - {{tweet.text}}</li>",
      //   "dateFormat": "%d %m %Y",
      //   "params": {
      //     "screen_name": "ntc_eventos",
      //     "count": 15,
      //     "screen_name": null,
      //     "list_id": null,
      //     "slug": null,
      //     "q": null,
      //     "exclude_replies": false,
      //     "include_rts": false
      //   }
      // });

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Instagram Feed - footer e menu lateral
  // ==========================================================================
  (function () {
    try {
      if (!isInPage(".listagem-instagram"))
        return false;

      var instagramFeed = new Instafeed({
        get: 'user',
        limit: 8,
        target: "instafeed",
        userId: 5545735206,
        accessToken: '5545735206.1677ed0.5ee48fd28a3840029e5c738a81c87fa1',
        resolution: 'thumbnail',
        template: '<li class="item-instagram orientation-{{orientation}}">' +
          '<a class="cover" href="{{link}}" target="_blank" title="{{caption}}">' +
          '<img class="thumbnail -instagram -{{orientation}}" src="{{image}}" alt="{{caption}}">' +
          '</a>' +
          '</li>',
        after: function () {}
      });

      $('.listagem-instagram').each(function () {
        instagramFeed.run();
      });

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());

  // Instagram Feed - footer e menu lateral
  // ==========================================================================
  (function () {
    try {
      if (!isInPage(".galeria-instagram"))
        return false;

      var instagramFeed = new Instafeed({
        get: 'user',
        limit: 16,
        target: "galeriainstagram",
        userId: 5545735206,
        accessToken: '5545735206.1677ed0.5ee48fd28a3840029e5c738a81c87fa1',
        // resolution: 'thumbnail',
        template: '<li class="col-md-3 p-1 item-instagram orientation-{{orientation}}">' +
          '<a class="cover" href="{{link}}" target="_blank" title="{{caption}}">' +
          '<img class="thumbnail -instagram -{{orientation}}" src="{{image}}" alt="{{caption}}">' +
          '</a>' +
          '</li>',
        after: function () {}
      });

      $('.galeria-instagram').each(function () {
        instagramFeed.run();
      });

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());

  // Fixar Aside na página solucao educacional
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#asideCards"))
        return false;

      $("#asideCards").stick_in_parent();

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());

  // Slider Usado nas páginas: In-company e MGPC
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#sliderFullscreen"))
        return false;

      var homeSlider = $('#sliderFullscreen').show().revolution({
        sliderType: "standard",
        sliderLayout: "fullscreen",
        delay: 9000,
        // extensions: "",
        navigation: {
          arrows: {
            style: "hermes",
            enable: true,
            hide_onmobile: true,
            hide_under: 600,
            hide_onleave: true,
            hide_delay: 200,
            hide_delay_mobile: 1200,
            tmp: '<div class="tp-arr-allwrapper"><div class="tp-arr-imgholder"></div></div>',
            left: {
              h_align: "left",
              v_align: "center",
              h_offset: 0,
              v_offset: 0
            },
            right: {
              h_align: "right",
              v_align: "center",
              h_offset: 0,
              v_offset: 0
            }
          }
        },
        gridwidth: [1200, 992, 460, 360, 320],
        gridheight: [519, 519, 460, 320]
      });

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());

  // 3D Slider - página Eventos (ASIDE)
  // ==========================================================================
  (function () {
    try {
      if (!isInPage(".swiper-container"))
        return false;

      // Params
      var sliderSelector = '.swiper-container',
        isMove = false,
        options = {
          init: false,
          loop: true,
          speed: 800,
          autoplay: {
            delay: 3000
          },
          effect: 'cube', // 'cube', 'fade', 'coverflow',
          cubeEffect: {
            shadow: true,
            slideShadows: true,
            shadowOffset: 40,
            shadowScale: 0.94,
          },
          grabCursor: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          // Events
          on: {
            init: function () {
              this.autoplay.stop();
            },
            imagesReady: function () {
              this.el.classList.remove('loading');
              this.autoplay.start();
            },
            touchMove: function (event) {
              if (!isMove) {
                this.el.classList.remove('scale-in');
                this.el.classList.add('scale-out');
                isMove = true;
              }
            },
            touchEnd: function (event) {
              this.el.classList.remove('scale-out');
              this.el.classList.add('scale-in');
              setTimeout(function () {
                isMove = false;
              }, 300);
            },
            slideChangeTransitionStart: function () {
              // console.log('slideChangeTransitionStart ' + this.activeIndex);
              if (!isMove) {
                this.el.classList.remove('scale-in');
                this.el.classList.add('scale-out');
              }
            },
            slideChangeTransitionEnd: function () {
              // console.log('slideChangeTransitionEnd ' + this.activeIndex);
              if (!isMove) {
                this.el.classList.remove('scale-out');
                this.el.classList.add('scale-in');
              }
            },
            transitionStart: function () {
              // console.log('transitionStart ' + this.activeIndex);
            },
            transitionEnd: function () {
              // console.log('transitionEnd ' + this.activeIndex);
            },
            slideChange: function () {
              // console.log('slideChange ' + this.activeIndex);
              // console.log(this);
            }
          }
        },
        mySwiper = new Swiper(sliderSelector, options);

      // Initialize slider
      mySwiper.init();

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());
});